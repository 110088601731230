<template>
  <div class="moveEvidence">
    <fb-header slot="header"
               title="选择移动文件夹"
               fixed>
      <fb-button slot="left"
                 icon="back"
                 @click="clickBack"
                 size="small"
                 type="primary">
        返回
      </fb-button>
    </fb-header>
    <!-- 文件夹better-scroll容器 -->
    <div class="evidence"
         :class="{bordernone:!randerArray.length}"
         ref="scrollArea">
      <!-- 文件夹container -->
      <div class="evidence-cell-container"
           ref="evidenceContainer">
        <!-- 是否有上一级 -->
        <div class="evidence-cell"
             v-if="positionArray.length"
             @click="fileBack">
          ......
        </div>

        <!-- v-for列表渲染 -->
        <div class="evidence-cell"
             v-for="(item,index) in randerArray"
             :key="index"
             @click="fileInit(item)">
          <span class="evidence-left">
            <span class="iconfont icon-folder"></span>
            <span>{{item.fileName}}</span>
          </span>
          <span class="evidence-right"
                @click.prevent.stop="chooseRadioHandle(item)">
            <input type="radio"
                   name="file"
                   :value="item"
                   v-model="choosedFile"
                   :id="item.id"
                   class="inputcheckbox">
            <label class="iconfont icon-selected"
                   :for="item.id"></label>
          </span>

        </div>

      </div>

    </div>
    <div class="moveConfirm">
      <span @click="moveDown">移动</span>
    </div>
  </div>
</template>
<script>
import BScroll from 'better-scroll'
import { caseBase } from '~api-config'
import { mapState } from 'vuex'
export default {
  name: 'moveEvidence',
  props: ['choosedArray', 'choosedType', 'kangqianye'],
  data () {
    return {
      array: [], // 渲染数组
      positionArray: [], // 文件位置
      choosedFile: null,
      evidence: {} // 证据页
    }
  },
  computed: {
    randerArray () {
      var newArray = this.array.filter(element => {
        return (element.isDir && this.isSelf(element.id))
      })
      return newArray
    },
    ...mapState('caseDetail', {
      evidenceId: state => state.evidenceId
    }),
    ...mapState('user', {
      userId: state => state.userInfo.id
    }),
    caseId () {
      return this.$route.params.caseId
    }
  },
  methods: {
    // 返回（为完成）
    clickBack () {
      this.$emit('goback')
    },
    // 点击选中文件取消选中状态
    chooseRadioHandle (item) {
      if (this.choosedFile && item.id === this.choosedFile.id) {
        this.choosedFile = null
      } else {
        this.choosedFile = item
      }
    },
    isSelf (id) {
      var a = true
      this.choosedArray.forEach(element => {
        if (element.item.id === id) {
          a = false
        }
      })
      return a
    },
    // 点击进入文件夹下层
    fileInit (item) {
      // console.log('fileInit')
      this.$axios.get(`${caseBase}/design/fileManage/list`, { params: { pId: item.id, creater: this.userId } })
        .then((res) => {
          this.array = res.data.data.file
          this.positionArray.push(item)
          // console.log(res)
        })
      this.choosedFile = null
    },
    // 返回上层文件夹
    fileBack () {
      if (this.positionArray.length > 1) { // 有上层pid
        // console.log('回到上层')
        this.$axios.get(`${caseBase}/design/fileManage/list`, { params: { pId: this.positionArray[this.positionArray.length - 1].pId, creater: this.userId } })
          .then((res) => {
            this.array = res.data.data.file
            this.positionArray.pop()
          })
      } else { // 没有上层pid请求证据页页面
        // console.log('回到证据页')
        this.$axios.get(`${caseBase}/design/fileManage/list`, { params: { caseId: this.caseId, pId: this.evidence.id, creater: this.userId } })
          .then((res) => {
            // console.log(res)
            this.array = res.data.data.file
            this.positionArray.pop()
          })
      }
      this.choosedFile = null
    },
    // 完成移动
    moveDown () {
      // console.log(this.choosedFile)
      const loading = this.$toast.loading({
        message: '移动中...'
      })
      if (this.choosedFile) { // 如果选中文件夹，则移动到文件夹中
        if (this.kangqianye === this.choosedFile.id) {
          this.clickBack()
          return
        }
        this.choosedArray.forEach((element, index) => {
          // 修改pId
          element.pId = this.choosedFile.id
          // 传入参数
          var paramValue = `id=${element.item.id}&pId=${element.pId}&creater=${this.userId}`
          // if (this.choosedType == 0) {//在证据页的文件要移动，需要把caseId和type给改掉
          //   paramValue += `&casseId=0&type=0`;
          // }
          this.$axios.put(`${caseBase}/design/fileManage/update?` + paramValue)
            .then((res) => {
            })
          if (this.choosedArray.length - 1 === index) {
            // 最后
            this.$emit('moved', this.choosedFile.id, loading)
          }
        })
      } else { // 如果没有选中文件，则移动到当前页
        if (this.positionArray.length) { // 不在证据页
          if (this.kangqianye === this.positionArray[this.positionArray.length - 1].id) {
            this.clickBack()
            return
          }
          this.choosedArray.forEach((element, index) => {
            element.pId = this.positionArray[this.positionArray.length - 1].id
            var paramValue = `id=${element.item.id}&pId=${element.pId}&creater=${this.userId}`
            // if (this.choosedType == 0) {//在证据页的文件要移动，需要把caseId和type给改掉
            //   paramValue += `&casseId=0&type=0`;
            // }
            this.$axios.put(`${caseBase}/design/fileManage/update?` + paramValue)
              .then((res) => {
                // console.log(res)
              })
            if (this.choosedArray.length - 1 === index) {
              // 最后
              this.$emit('moved', this.positionArray[this.positionArray.length - 1].id, loading)
            }
          })
        } else { // 在证据页
          if (this.kangqianye === this.evidence.id) {
            this.clickBack()
            return
          }
          this.choosedArray.forEach((element, index) => {
            element.pId = this.evidence.id
            this.$axios.put(`${caseBase}/design/fileManage/update?` + `id=${element.item.id}&caseId=${this.caseId}&pId=${element.pId}&creater=${this.userId}`)
              .then((res) => {
                // console.log(res)
              })
            if (this.choosedArray.length - 1 === index) {
              // 最后
              this.$emit('moved', this.evidence.id, loading)
            }
          })
        }
      }
    }
  },
  mounted () {
    // betterscroll设置
    this.scroll = new BScroll(this.$refs.scrollArea, { click: true })
    // 挂载信息
    this.evidence.id = this.evidenceId
    if (!this.evidence.id) {
      this.$toast.loading({
        message: '加载失败...'
      })
      return
    }
    const loading = this.$toast.loading({
      message: '加载中...'
    })
    this.$axios.get(`${caseBase}/design/fileManage/list`, { params: { caseId: this.caseId, creater: this.userId, pId: this.evidence.id } })
      .then(res => {
        this.array = res.data.data.file
        loading.clear()
      })
  }
}
</script>
<style scoped>
.moveEvidence {
  width: 100vw;
  height: 100vh;
  background-color: white;
  padding-top: 60px;
}
.evidence {
  width: 345px;
  margin: 15px auto;
  border-radius: 5px;
  border: 1px solid #00afff80;
  color: #666666;
  padding: 0 11px;
  overflow: hidden;
  overflow: scroll;
  max-height: calc(100vh - 137px);
}
.evidence.bordernone {
  height: 44px;
}
.evidence-top {
  height: 44px;
  border-bottom: 1px #d2d2d2 solid;
  padding: 11px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 15px;
  color: #169bd5;
}
.evidence-top .iconfont {
  margin-left: 20px;
  font-size: 25px;
  color: #169bd5;
}
.evidence-cell {
  height: 44px;
  border-bottom: 1px #d2d2d2 solid;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.evidence-cell:nth-last-child(1) {
  border-bottom: none;
}
.evidence-left {
  font-size: 15px;
}
.evidence-left .iconfont {
  font-size: 30px;
  color: #169bd5;
  vertical-align: middle;
  margin-right: 11px;
}
/*不是文件夹的情况*/
.fileicon {
  vertical-align: middle;
  margin-right: 11px;
}
.evidence-right {
  position: relative;
}
.evidence-right label {
  color: gray;
}
.evidence-right > input[type="radio"]:checked + label.iconfont {
  color: #478eee;
}
.inputcheckbox {
  position: absolute;
  opacity: 0;
}
.dialoged-input {
  display: flex;
  align-items: center;
  justify-content: center;
}
.moveConfirm {
  padding-right: 50px;
  text-align: right;
  font-size: 20px;
  color: #478eee;
}
</style>
