<template>
  <div class="evidence">
    <div class="evidence-top">
      <span>证据</span>
      <span>
        <span class="iconfont icon-shot" @click="takePhoto"></span>
        <span class="iconfont icon-add-folder" @click="addfile"></span>
        <span class="iconfont icon-done" @click="editoredHandle"></span>
      </span>
    </div>
    <div class="evidence-cell-box" :class="{ boxChoosed: editored }" ref="scrollArea">
      <div class="evidence-cell-container">
        <div class="evidence-cell" v-if="positionArray.length" @click="fileBack">......</div>
        <label class="evidence-cell" v-for="(item, index) in array" :key="index" :for="item.id" @click="fileInit(item, $event)">
          <span class="evidence-left">
            <span v-if="item.isDir" class="iconfont icon-folder"></span>
            <span v-else :class="fileType(item.fileName)" class="iconfont"></span>
            <span>{{ item.fileName }}</span>
          </span>
          <span class="evidence-right" v-show="editored">
            <input type="checkbox" :value="{ item, index }" v-model="choosedArray" :id="item.id" class="inputcheckbox" />
            <span class="iconfont icon-selected"></span>
          </span>
        </label>
      </div>
    </div>
    <!--  添加文件夹弹出框 -->
    <van-dialog v-model="dialoged" title="请输入文件夹名称" :show-cancel-button="true" @confirm="confirmAddFile">
      <div class="dialoged-input">
        <van-field placeholder="请输入文件夹名称" v-model="addfileName" />
      </div>
    </van-dialog>
    <!--  生成pdf弹出框 -->
    <van-dialog v-model="generate" title="请输入生成pdf的名称" :show-cancel-button="true" @confirm="generatePdf" @cancel="cancelGeneratePdf">
      <div class="dialoged-input">
        <van-field placeholder="请输入生成pdf的名称" v-model="generatePdfName" />
      </div>
    </van-dialog>
    <!-- 重命名弹出框 -->
    <van-dialog v-model="isRename" title="请输入新名称" :show-cancel-button="true" @confirm="renaming" @cancel="cancelRename">
      <div class="dialoged-input">
        <van-field placeholder="请输入新名称" v-model="renameName" />
      </div>
    </van-dialog>
    <!-- 提示信息弹出框 -->
    <van-dialog v-model="showMessage" :message="message" />
    <!-- 图片弹出框 -->
    <van-popup v-model="showImg">
      <img :src="qiniuUrl + imgSrc" />
    </van-popup>
    <van-popup v-model="showPdf" @click-overlay="closePdf">
      <iframe v-if="pdfSrc" :src="pdfSrc" frameborder="0" style="-webkit-overflow-scrolling: touch; overflow: auto; overflow: auto" class="iframeClass"></iframe>
    </van-popup>
    <!-- 移动文件夹弹出框 -->
    <van-popup v-model="showMove">
      <move-evidence v-if="showMove" :choosedArray="choosedArray" :choosedType="positionArray.length" :kangqianye="kangqianye" @goback="goback" @moved="moved" />
    </van-popup>
    <!-- 编辑栏 -->
    <div class="evidencetool" v-show="editored">
      <span @click="movehandle">
        <span class="iconfont icon-ic_movetofolder"></span>
        <span>移动</span>
      </span>
      <span @click="chooseImg">
        <span class="iconfont icon-pdf"></span>
        <span>生成pdf</span>
      </span>
      <span @click="renameFile">
        <span class="iconfont icon-edit-2"></span>
        <span>重命名</span>
      </span>
      <span @click="deleteHandle">
        <span class="iconfont icon-delete"></span>
        <span>删除</span>
      </span>
    </div>
  </div>
</template>
<script>
import { caseBase, qiniuBase } from '~api-config'
import { mapState } from 'vuex'
import BScroll from 'better-scroll'
import moveEvidence from './moveEvidence'
export default {
  name: 'evidencezujian',
  components: {
    moveEvidence,
  },
  data() {
    return {
      array: [],
      evidence: {}, // 证据页面数据
      fileIconClassMap: {
        // 字体图标map
        jpg: 'icon-picture',
        jpeg: 'icon-picture',
        png: 'icon-picture',
        bmp: 'icon-picture',
        gif: 'icon-picture',
        pdf: 'icon-pdf',
        doc: 'icon-wordwenjian',
        docx: 'icon-wordwenjian',
        xls: 'icon-excelwenjian',
        xlsx: 'icon-excelwenjian',
      },
      showImg: false, // 预览图片的开关
      imgSrc: '', // 预览图片的路径  http://ovucajwy3.bkt.clouddn.com/FsN-WrNZMo6leQ5mM6FIKq9EfNFw
      showPdf: false, // pdf预览
      pdfSrc: '', // pdf预览路径
      dialoged: false, // 弹框开关，添加文件夹的弹框
      addfileName: '', // 添加文件夹的文件夹名称
      showMessage: false, // 弹框开关，提示信息弹框
      message: '', // 提示信息类容
      showMove: false, // 开始移动
      editored: false, // 编辑状态的开关
      choosedArray: [], // 选中文件
      positionArray: [], // 位置文件
      qiniuUrl: 'http://ovucajwy3.bkt.clouddn.com/', // 其七牛云网址
      loadUrl: 'http://dcsapi.com/', // 永中网址
      loadUrlKValue: 114795396, // 永中key
      choosedImgs: [], // 选中图片
      dirArray: [], // 选中文件夹
      generate: false, // 生成pdf开关
      generatePdfName: '', // 生成pdf的文件名
      isRename: false, // 重命名开关
      renameName: '', // 重命名新名字
    }
  },
  computed: {
    // 当前页pId
    kangqianye() {
      if (this.positionArray.length === 0) {
        // 证据页
        return this.evidence.id
      } else {
        // 在证据页当前pid为0，不在证据页就返回当前页pid
        return this.positionArray[this.positionArray.length - 1].id
      }
    },
    ...mapState('user', {
      userId: (state) => state.userInfo.id,
    }),
    ...mapState('caseDetail', {
      evidenceId: (state) => state.evidenceId,
    }),
    caseId() {
      return this.$route.params.caseId
    },
  },
  created() {
    window.addEvidenceResult = this.addEvidenceResultCallback
    window.refreshEvidence = this.refreshEvidence
  },
  methods: {
    // 刷新
    refreshEvidence() {
      this.$axios
        .get(`${caseBase}/design/fileManage/list`, {
          params: { creater: this.userId, pId: this.kangqianye },
        })
        .then((res) => {
          this.array = res.data.data.file
        })
    },
    // 接收 使用原生相机拍照回调信息
    addEvidenceResultCallback(data) {
      /*
       * code 0-失败 1-成功
       * message 消息
       */
      // console.log('拍照结束返回值', data)
      // ios返回目前是json格式的字符串等待解决中
      if (typeof data === 'string') {
        data = JSON.parse(data)
        // console.log(data)
      }
      if (data.code) {
        this.array.push(...data.data.files)
      } else {
        // console.log('拍照失败')
      }
    },
    // 拍照
    takePhoto() {
      // 将当前路径传给安卓端，等安卓拍照上传完成之后再跳转回来
      const caseId = parseInt(this.$route.params.caseId)
      const pId = parseInt(this.kangqianye)
      // console.log('给案件 %s 添加证据', caseId, 'pid', pId)
      try {
        // window.webkit.messageHandlers.showCamera.postMessage({})
        // AND2JS.showCamera(caseId, pId)
        const u = navigator.userAgent
        if (u === 'fb-Android-Webview') {
          // eslint-disable-next-line
          AND2JS.showCamera(caseId, pId)
        } else if (u === 'fb-iOS-Webview') {
          // eslint-disable-next-line
          window.webkit.messageHandlers.showCamera.postMessage({
            caseId: caseId,
            pId: pId,
          })
        } else if (u === 'fb-flutter-Webview') {
          // eslint-disable-next-line
          window.showCamera.postMessage(JSON.stringify({ caseId, pId }))
        } else {
          this.$toast('非原生环境 不支持打开相机')
        }
      } catch (err) {
        console.log('showCamera.postMessage', err)
      }
    },

    fileType(name) {
      if (!name) {
        return ''
      }
      var nameArray = name.split('.')
      var houzui = nameArray[nameArray.length - 1]
      if (this.fileIconClassMap[houzui]) {
        return this.fileIconClassMap[houzui]
      } else {
        return 'icon-doc'
      }
    },

    // 编辑(完成)
    editoredHandle() {
      this.editored = !this.editored
      this.choosedArray = []
    },

    // 进入下一级和点击文件的处理(进入下一级完成)
    fileInit(item, event) {
      if (this.editored) {
        return
      }
      event.preventDefault()
      console.log(item, '====>>> 接口返参08')
      // console.log(item.fileName)
      if (item.isDir) {
        // 是文件夹
        // console.log('文件夹类型')
        // 发请求
        this.$axios
          .get(`${caseBase}/design/fileManage/list`, {
            params: { creater: this.userId, pId: item.id },
          })
          .then((res) => {
            this.array = res.data.data.file
            this.positionArray.push(item)
            // console.log(res)
          })
      } else {
        // 文件状态的处理（为完成）
        // console.log('文件类型')
        if (this.fileType(item.fileName) === 'icon-picture') {
          // console.log('是图片')
          // 这里图片路径要用哈希值
          // this.imgSrc = item.filePath
          // this.showImg = true
          const imgArray = this.array.filter((element) => {
            if (!element.isDir && this.fileType(item.fileName) === 'icon-picture') {
              return true
            } else {
              return false
            }
          })
          let selectedIndex = null
          imgArray.forEach((element, index) => {
            if (element.id === item.id) {
              selectedIndex = index
            }
          })
          const mapedArray = imgArray.map((element) => {
            const listItem = {}
            listItem.previewFileUrl = element.filePath
            listItem.previewFileName = element.fileName
            listItem.previewFileId = element.id
            return listItem
          })
          const string = JSON.stringify({ data: mapedArray, index: selectedIndex })
          try {
            const u = navigator.userAgent
            if (u === 'fb-Android-Webview') {
              // eslint-disable-next-line
              AND2JS.showPreviewPhotos(string)
            } else if (u === 'fb-flutter-Webview') {
              // eslint-disable-next-line
              window.showPreviewPhotos.postMessage(string)
            } else if (u === 'fb-iOS-Webview') {
              window.webkit.messageHandlers.showEvidencePhotoPreview.postMessage({
                params: string,
              })
            } else {
              // console.log('环境不对')
            }
          } catch (err) {
            console.log('showEvidencePhotoPreview.postMessage', err)
          }
        } else {
          // console.log('不是图片（word，pdf，excel...）')
          // AND2JS
          try {
            const u = navigator.userAgent
            if (u === 'fb-Android-Webview') {
              // eslint-disable-next-line
              AND2JS.showFilePreview(item.filePath, item.fileName)
            } else if (u === 'fb-iOS-Webview') {
              // eslint-disable-next-line
              window.webkit.messageHandlers.showFilePreview.postMessage({
                filePath: item.filePath,
                fileName: item.fileName,
              })
            } else if (u === 'fb-flutter-Webview') {
              // 找不到位置
              // eslint-disable-next-line
              window.showFilePreview.postMessage(
                JSON.stringify({
                  filePath: item.filePath,
                  fileName: item.fileName,
                })
              )
            } else {
              // console.log('环境不对')
            }
          } catch (err) {
            console.log('showFilePreview.postMessage', err)
          }
        }
      }
    },

    // 回到上层(完成)
    fileBack() {
      if (this.positionArray.length > 1) {
        // 有上层pid
        // console.log('回到上层')
        this.$axios
          .get(`${caseBase}/design/fileManage/list`, {
            params: {
              creater: this.userId,
              pId: this.positionArray[this.positionArray.length - 1].pId,
            },
          })
          .then((res) => {
            this.array = res.data.data.file
            this.positionArray.pop()
          })
      } else {
        // 没有上层pid请求证据页页面
        // console.log('回到证据页')
        this.$axios
          .get(`${caseBase}/design/fileManage/list`, {
            params: { caseId: this.caseId, creater: this.userId, pId: this.evidence.id },
          })
          .then((res) => {
            // console.log(res)
            this.array = res.data.data.file
            this.positionArray.pop()
          })
      }
      this.editored = false
      this.choosedArray = []
    },

    // 点击添加文件夹(完成)
    addfile() {
      this.addfileName = ''
      this.dialoged = true
    },

    // 添加文件夹弹框(完成)
    confirmAddFile() {
      if (this.addfileName.trim() === '') {
        // console.log('不能为空')
        this.message = '文件夹名称不能为空'
        this.showMessage = true
        return
      }
      if (this.positionArray.length === 0) {
        // 证据根目录添加文件
        this.$axios
          .post(`${caseBase}/design/fileManage/add`, {
            isDir: 1,
            caseId: this.caseId,
            fileName: this.addfileName,
            creater: this.userId,
            pId: this.evidence.id,
          })
          .then((res) => {
            // console.log(res)
            this.array.push(res.data.data.file)
            this.addfileName = ''
          })
      } else {
        // 不在根目录中是有pId的，所以用pId
        this.$axios
          .post(`${caseBase}/design/fileManage/add`, {
            pId: this.positionArray[this.positionArray.length - 1].id,
            isDir: 1,
            fileName: this.addfileName,
            creater: this.userId,
            caseId: this.caseId,
          })
          .then((res) => {
            // console.log(res)
            this.array.push(res.data.data.file)
            this.addfileName = ''
          })
      }
    },
    // 删除(完成)
    deleteHandle() {
      if (!this.choosedArray.length) {
        // console.log('没有选中文件夹删除')
        this.message = '至少要选中一个文件或文件夹'
        this.showMessage = true
        return
      }
      const loading = this.$toast.loading({
        message: '正在删除...',
      })
      var choosedArray = this.choosedArray.sort((a, b) => {
        return a.index < b.index
      })
      choosedArray.forEach((element, index) => {
        // 发请求删除文件
        this.$axios.delete(`${caseBase}/design/fileManage/delete/` + element.item.id).then((res) => {
          if (this.choosedArray.length - 1 === index) {
            // // console.log('删除完了')
            loading.clear()
            choosedArray.forEach((item) => {
              this.array.splice(item.index, 1)
            })
            this.choosedArray = []
          }
        })
      })
    },
    // 点击重命名
    renameFile() {
      if (this.choosedArray.length !== 1) {
        // console.log('只能选中一个文件重命名')
        this.message = '只有在选中一个文件或者文件夹时才能重命名'
        this.showMessage = true
        return
      }
      // 文件夹显示全名文件要去掉后缀
      if (this.choosedArray[0].item.isDir) {
        this.renameName = this.choosedArray[0].item.fileName
      } else {
        const nameArray = this.choosedArray[0].item.fileName.split('.')
        this.renameName = nameArray[0]
      }
      this.isRename = true
    },
    renaming() {
      // console.log('确定修改')
      const loading = this.$toast.loading({
        message: '修改中...',
      })
      const id = this.choosedArray[0].item.id
      let fileName
      if (this.choosedArray[0].item.isDir) {
        fileName = this.renameName
      } else {
        const nameArray = this.choosedArray[0].item.fileName.split('.')
        const houzhui = nameArray[nameArray.length - 1]
        fileName = this.renameName + '.' + houzhui
      }
      this.$axios.put(`${caseBase}/design/fileManage/update?id=${id}&fileName=${fileName}`).then((res) => {
        // console.log('修改名称成功', res)
        this.choosedArray[0].item.fileName = fileName
        loading.clear()
        this.choosedArray = []
        this.renameName = ''
      })
    },
    cancelRename() {
      // console.log('取消修改')
      this.choosedArray = []
      this.renameName = ''
    },
    // 移动文件夹
    movehandle() {
      if (!this.choosedArray.length) {
        // console.log('没有选中文件夹移动')
        this.message = '至少要选中一个文件或文件夹'
        this.showMessage = true
        return
      }
      // console.log('move', this.choosedArray)
      this.showMove = true
    },
    // 从移动状态返回
    goback() {
      this.choosedArray = []
      this.showMove = false
    },
    // 移动结束
    moved(pId, loading) {
      var choosedArray = this.choosedArray.sort((a, b) => {
        return a.index < b.index
      })
      choosedArray.forEach((item) => {
        this.array.splice(item.index, 1)
      })
      loading.clear()
      this.goback()
    },
    // 关闭渲染pdf蒙层 重置src
    closePdf() {
      this.pdfSrc = ''
    },
    // 生成pdf=>选择要生成pdf的img图片
    chooseImg() {
      if (!this.choosedArray.length) {
        // console.log('没有选中文件生成pdf')
        this.message = '至少要选中一个图片或者包含图片的文件夹'
        this.showMessage = true
        return
      }
      const loading = this.$toast.loading({
        message: '准备生成pdf...',
      })
      // 初始化存放图片数组和选中文件数组
      this.choosedImgs = []
      this.dirArray = []
      // 初始选中的的类容数组
      var beginArray = this.choosedArray.map((element) => {
        return element.item
      })
      beginArray.forEach((element) => {
        if (element.isDir) {
          this.dirArray.push(element)
        } else {
          if (this.fileType(element.fileName) === 'icon-picture') {
            this.choosedImgs.push(element)
            // console.log('push-begin')
          }
        }
      })
      // console.log('dirs', this.dirArray)
      // 文件夹向下查询
      if (this.dirArray[0]) {
        // 有下级
        this.findImgInDir(this.dirArray[0], 0, loading)
      } else {
        // 没有下级 结束
        // console.log('查询图片结束')
        loading.clear()
        if (!this.choosedImgs.length) {
          this.message = '至少要选中一个图片或者包含图片的文件夹'
          this.showMessage = true
          return
        }
        this.generate = true
        // 查询完所有图片
      }
    },
    findImgInDir(dir, index, loading) {
      this.$axios
        .get(`${caseBase}/design/fileManage/list`, {
          params: { pId: dir.id, creater: this.userId },
        })
        .then((res) => {
          res.data.data.file.forEach((element) => {
            if (element.isDir) {
              this.dirArray.push(element)
            } else {
              if (this.fileType(element.fileName) === 'icon-picture') {
                this.choosedImgs.push(element)
              }
            }
          })
          // 如果文件没查完向下继续查
          if (this.dirArray[index + 1]) {
            this.findImgInDir(this.dirArray[index + 1], index + 1, loading)
          } else {
            // console.log('查询图片结束')
            if (!this.choosedImgs.length) {
              this.message = '至少要选中一个图片或者包含图片的文件夹'
              this.showMessage = true
              return
            }
            loading.clear()
            this.generate = true
            // 查询完所有图片
          }
        })
    },
    // 生成pdf
    generatePdf(name) {
      // console.log('准备生成pdf', this.choosedImgs)
      const loading = this.$toast.loading({
        message: '正在生成pdf...',
      })
      const hashs = this.choosedImgs.map((item) => {
        const houzuiArray = item.fileName.split('.')
        return item.filePath + '.' + houzuiArray[houzuiArray.length - 1]
      })
      const paramsHashs = hashs.toString()
      if (this.generatePdfName.trim() === '') {
        // console.log('不能为空')
        this.message = 'pdf名称不能为空'
        this.showMessage = true
        return
      }
      let pdfName
      if (/\.pdf$/.test(this.generatePdfName)) {
        pdfName = this.generatePdfName
      } else {
        pdfName = this.generatePdfName + '.pdf'
      }
      this.$axios.post(`${caseBase}/design/catalog/convert2Pdf?userId=${this.userId}&caseId=${this.caseId}&fileName=${pdfName}&hashs=${paramsHashs}`).then((res) => {
        // console.log('生成pdf的结果', res)
        loading.clear()
        this.message = 'pdf文件已生成在我的文档中'
        this.showMessage = true
        this.choosedArray = []
      })
    },
    // 取消pdf
    cancelGeneratePdf() {
      // console.log('取消pdf')
    },
  },
  mounted() {
    this.qiniuUrl = qiniuBase
    // betterscroll设置
    this.scroll = new BScroll(this.$refs.scrollArea, { click: true })
    // 挂载信息
    // console.log('证据id', this.evidenceId)
    this.evidence.id = this.evidenceId
    if (!this.evidence.id) {
      this.$toast.loading({
        message: '加载失败...',
      })
      return
    }
    const loading = this.$toast.loading({
      message: '加载中...',
    })
    this.$axios
      .get(`${caseBase}/design/fileManage/list`, {
        params: { caseId: this.caseId, creater: this.userId, pId: this.evidence.id },
      })
      .then((res) => {
        // console.log('证据查询结果', res)
        this.array = res.data.data.file
        loading.clear()
      })
  },
}
</script>
<style scoped>
.evidence {
  width: 345px;
  margin: 15px auto;
  border-radius: 5px;
  border: 1px solid #00afff80;
  color: #666666;
}
.evidence.evidencebottom {
  margin-bottom: 65px;
}
.evidence-top {
  height: 44px;
  border-bottom: 1px #d2d2d2 solid;
  padding: 11px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 15px;
  color: #169bd5;
}
.evidence-top .iconfont {
  margin-left: 20px;
  font-size: 25px;
  color: #169bd5;
}
.evidence-cell-box {
  padding: 0 11px;
  overflow: hidden;
  overflow: scroll;
  /* scroll */
  max-height: calc(100vh - 175px);
}
.evidence-cell-box.boxChoosed {
  max-height: calc(100vh - 205px);
}
.evidence-cell {
  height: 44px;
  border-bottom: 1px #d2d2d2 solid;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.evidence-cell:nth-last-child(1) {
  border-bottom: none;
}
.evidence-left {
  font-size: 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-grow: 1;
}
.evidence-left .iconfont {
  font-size: 30px;
  color: #169bd5;
  vertical-align: middle;
  margin-right: 11px;
}
/*不是文件夹的情况*/
.fileicon {
  vertical-align: middle;
  margin-right: 11px;
}
.evidence-right {
  position: relative;
}
.evidence-right span {
  color: gray;
}
.evidence-right > input[type='checkbox']:checked + span.iconfont {
  color: #478eee;
}
.inputcheckbox {
  position: absolute;
  opacity: 0;
}
.dialoged-input {
  display: flex;
  align-items: center;
  justify-content: center;
}
.evidencetool {
  width: 100%;
  background-color: white;
  height: 50px;
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 9px;
  color: #999999;
}
.evidencetool > span {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.evidencetool > span > span.iconfont {
  font-size: 25px;
  color: #169bd5;
}
.iframeClass {
  height: 550px;
}
</style>
