var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "moveEvidence" },
    [
      _c(
        "fb-header",
        {
          attrs: { slot: "header", title: "选择移动文件夹", fixed: "" },
          slot: "header",
        },
        [
          _c(
            "fb-button",
            {
              attrs: {
                slot: "left",
                icon: "back",
                size: "small",
                type: "primary",
              },
              on: { click: _vm.clickBack },
              slot: "left",
            },
            [_vm._v(" 返回 ")]
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          ref: "scrollArea",
          staticClass: "evidence",
          class: { bordernone: !_vm.randerArray.length },
        },
        [
          _c(
            "div",
            {
              ref: "evidenceContainer",
              staticClass: "evidence-cell-container",
            },
            [
              _vm.positionArray.length
                ? _c(
                    "div",
                    {
                      staticClass: "evidence-cell",
                      on: { click: _vm.fileBack },
                    },
                    [_vm._v(" ...... ")]
                  )
                : _vm._e(),
              _vm._l(_vm.randerArray, function (item, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass: "evidence-cell",
                    on: {
                      click: function ($event) {
                        return _vm.fileInit(item)
                      },
                    },
                  },
                  [
                    _c("span", { staticClass: "evidence-left" }, [
                      _c("span", { staticClass: "iconfont icon-folder" }),
                      _c("span", [_vm._v(_vm._s(item.fileName))]),
                    ]),
                    _c(
                      "span",
                      {
                        staticClass: "evidence-right",
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            $event.stopPropagation()
                            return _vm.chooseRadioHandle(item)
                          },
                        },
                      },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.choosedFile,
                              expression: "choosedFile",
                            },
                          ],
                          staticClass: "inputcheckbox",
                          attrs: { type: "radio", name: "file", id: item.id },
                          domProps: {
                            value: item,
                            checked: _vm._q(_vm.choosedFile, item),
                          },
                          on: {
                            change: function ($event) {
                              _vm.choosedFile = item
                            },
                          },
                        }),
                        _c("label", {
                          staticClass: "iconfont icon-selected",
                          attrs: { for: item.id },
                        }),
                      ]
                    ),
                  ]
                )
              }),
            ],
            2
          ),
        ]
      ),
      _c("div", { staticClass: "moveConfirm" }, [
        _c("span", { on: { click: _vm.moveDown } }, [_vm._v("移动")]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }