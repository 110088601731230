var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "evidence" },
    [
      _c("div", { staticClass: "evidence-top" }, [
        _c("span", [_vm._v("证据")]),
        _c("span", [
          _c("span", {
            staticClass: "iconfont icon-shot",
            on: { click: _vm.takePhoto },
          }),
          _c("span", {
            staticClass: "iconfont icon-add-folder",
            on: { click: _vm.addfile },
          }),
          _c("span", {
            staticClass: "iconfont icon-done",
            on: { click: _vm.editoredHandle },
          }),
        ]),
      ]),
      _c(
        "div",
        {
          ref: "scrollArea",
          staticClass: "evidence-cell-box",
          class: { boxChoosed: _vm.editored },
        },
        [
          _c(
            "div",
            { staticClass: "evidence-cell-container" },
            [
              _vm.positionArray.length
                ? _c(
                    "div",
                    {
                      staticClass: "evidence-cell",
                      on: { click: _vm.fileBack },
                    },
                    [_vm._v("......")]
                  )
                : _vm._e(),
              _vm._l(_vm.array, function (item, index) {
                return _c(
                  "label",
                  {
                    key: index,
                    staticClass: "evidence-cell",
                    attrs: { for: item.id },
                    on: {
                      click: function ($event) {
                        return _vm.fileInit(item, $event)
                      },
                    },
                  },
                  [
                    _c("span", { staticClass: "evidence-left" }, [
                      item.isDir
                        ? _c("span", { staticClass: "iconfont icon-folder" })
                        : _c("span", {
                            staticClass: "iconfont",
                            class: _vm.fileType(item.fileName),
                          }),
                      _c("span", [_vm._v(_vm._s(item.fileName))]),
                    ]),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.editored,
                            expression: "editored",
                          },
                        ],
                        staticClass: "evidence-right",
                      },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.choosedArray,
                              expression: "choosedArray",
                            },
                          ],
                          staticClass: "inputcheckbox",
                          attrs: { type: "checkbox", id: item.id },
                          domProps: {
                            value: { item, index },
                            checked: Array.isArray(_vm.choosedArray)
                              ? _vm._i(_vm.choosedArray, { item, index }) > -1
                              : _vm.choosedArray,
                          },
                          on: {
                            change: function ($event) {
                              var $$a = _vm.choosedArray,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = { item, index },
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    (_vm.choosedArray = $$a.concat([$$v]))
                                } else {
                                  $$i > -1 &&
                                    (_vm.choosedArray = $$a
                                      .slice(0, $$i)
                                      .concat($$a.slice($$i + 1)))
                                }
                              } else {
                                _vm.choosedArray = $$c
                              }
                            },
                          },
                        }),
                        _c("span", { staticClass: "iconfont icon-selected" }),
                      ]
                    ),
                  ]
                )
              }),
            ],
            2
          ),
        ]
      ),
      _c(
        "van-dialog",
        {
          attrs: { title: "请输入文件夹名称", "show-cancel-button": true },
          on: { confirm: _vm.confirmAddFile },
          model: {
            value: _vm.dialoged,
            callback: function ($$v) {
              _vm.dialoged = $$v
            },
            expression: "dialoged",
          },
        },
        [
          _c(
            "div",
            { staticClass: "dialoged-input" },
            [
              _c("van-field", {
                attrs: { placeholder: "请输入文件夹名称" },
                model: {
                  value: _vm.addfileName,
                  callback: function ($$v) {
                    _vm.addfileName = $$v
                  },
                  expression: "addfileName",
                },
              }),
            ],
            1
          ),
        ]
      ),
      _c(
        "van-dialog",
        {
          attrs: { title: "请输入生成pdf的名称", "show-cancel-button": true },
          on: { confirm: _vm.generatePdf, cancel: _vm.cancelGeneratePdf },
          model: {
            value: _vm.generate,
            callback: function ($$v) {
              _vm.generate = $$v
            },
            expression: "generate",
          },
        },
        [
          _c(
            "div",
            { staticClass: "dialoged-input" },
            [
              _c("van-field", {
                attrs: { placeholder: "请输入生成pdf的名称" },
                model: {
                  value: _vm.generatePdfName,
                  callback: function ($$v) {
                    _vm.generatePdfName = $$v
                  },
                  expression: "generatePdfName",
                },
              }),
            ],
            1
          ),
        ]
      ),
      _c(
        "van-dialog",
        {
          attrs: { title: "请输入新名称", "show-cancel-button": true },
          on: { confirm: _vm.renaming, cancel: _vm.cancelRename },
          model: {
            value: _vm.isRename,
            callback: function ($$v) {
              _vm.isRename = $$v
            },
            expression: "isRename",
          },
        },
        [
          _c(
            "div",
            { staticClass: "dialoged-input" },
            [
              _c("van-field", {
                attrs: { placeholder: "请输入新名称" },
                model: {
                  value: _vm.renameName,
                  callback: function ($$v) {
                    _vm.renameName = $$v
                  },
                  expression: "renameName",
                },
              }),
            ],
            1
          ),
        ]
      ),
      _c("van-dialog", {
        attrs: { message: _vm.message },
        model: {
          value: _vm.showMessage,
          callback: function ($$v) {
            _vm.showMessage = $$v
          },
          expression: "showMessage",
        },
      }),
      _c(
        "van-popup",
        {
          model: {
            value: _vm.showImg,
            callback: function ($$v) {
              _vm.showImg = $$v
            },
            expression: "showImg",
          },
        },
        [_c("img", { attrs: { src: _vm.qiniuUrl + _vm.imgSrc } })]
      ),
      _c(
        "van-popup",
        {
          on: { "click-overlay": _vm.closePdf },
          model: {
            value: _vm.showPdf,
            callback: function ($$v) {
              _vm.showPdf = $$v
            },
            expression: "showPdf",
          },
        },
        [
          _vm.pdfSrc
            ? _c("iframe", {
                staticClass: "iframeClass",
                staticStyle: {
                  "-webkit-overflow-scrolling": "touch",
                  overflow: "auto",
                },
                attrs: { src: _vm.pdfSrc, frameborder: "0" },
              })
            : _vm._e(),
        ]
      ),
      _c(
        "van-popup",
        {
          model: {
            value: _vm.showMove,
            callback: function ($$v) {
              _vm.showMove = $$v
            },
            expression: "showMove",
          },
        },
        [
          _vm.showMove
            ? _c("move-evidence", {
                attrs: {
                  choosedArray: _vm.choosedArray,
                  choosedType: _vm.positionArray.length,
                  kangqianye: _vm.kangqianye,
                },
                on: { goback: _vm.goback, moved: _vm.moved },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.editored,
              expression: "editored",
            },
          ],
          staticClass: "evidencetool",
        },
        [
          _c("span", { on: { click: _vm.movehandle } }, [
            _c("span", { staticClass: "iconfont icon-ic_movetofolder" }),
            _c("span", [_vm._v("移动")]),
          ]),
          _c("span", { on: { click: _vm.chooseImg } }, [
            _c("span", { staticClass: "iconfont icon-pdf" }),
            _c("span", [_vm._v("生成pdf")]),
          ]),
          _c("span", { on: { click: _vm.renameFile } }, [
            _c("span", { staticClass: "iconfont icon-edit-2" }),
            _c("span", [_vm._v("重命名")]),
          ]),
          _c("span", { on: { click: _vm.deleteHandle } }, [
            _c("span", { staticClass: "iconfont icon-delete" }),
            _c("span", [_vm._v("删除")]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }